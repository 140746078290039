import React from 'react';
import { GrFacebookOption, GrTwitter } from 'react-icons/gr';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const About = ({
  pilotCity,
  pilotName,
  pilotDescription,
  demographicPDF,
  lastUpdated,
  aboutHeading,
}) => {
  const location = useLocation();

  return (
    <div
      className={`px-3 md:px-5 pt-3 md:pt-5 pb-2 md:pb-6 lg:pb-7 ${
        (pilotCity === 'St Paul PPP' ||
          pilotCity === 'Paterson' ||
          pilotCity === 'Cambridge' ||
          pilotCity === 'Richmond' ||
          pilotCity === 'Los Angeles' ||
          pilotCity === 'New Orleans' ||
          pilotCity === 'Columbia') &&
        'mb-[264px] md:mb-[132px] lg:mb-[140px]'
      }`}
    >
      <h2 className="mb-5">{aboutHeading || `About the ${pilotName}`}</h2>
      <p className="xl:max-w-[75%]">{pilotDescription}</p>
      <p className="my-4"> {lastUpdated}</p> {/* TODO: Find out where this data should come from */}
      <div className="flex flex-col items-center md:flex-row justify-center md:justify-start mb-4 md:my-4 lg:my-4">
        <div className="headings border-2 border-black border-[1px] px-5 py-2 mb-2 md:mb-0 md:mr-2 flex items-center">
          Share Via
          <FacebookShareButton
            url={location.href}
            description="Explore Guaranteed Income pilots across the U.S., and hear stories from participants at guaranteedincome.us"
          >
            <GrFacebookOption className="text-2xl mx-1 cursor-pointer" />
          </FacebookShareButton>
          <TwitterShareButton
            title="Explore Guaranteed Income pilots across the U.S., and hear stories from participants at "
            url={location.href}
          >
            <GrTwitter className="text-2xl cursor-pointer" />
          </TwitterShareButton>
        </div>
        {/* <a
          href={demographicPDF}
          target="_blank"
          rel="noreferrer noopener"
          className={`${pilotCity
            .replaceAll(' ', '-')
            .toLowerCase()} button border bg-bronze cta2 text-white cursor-pointer flex items-center hidden`}
        >
          Get demographic data (PDF)
        </a> */}
      </div>
    </div>
  );
};

export default About;
