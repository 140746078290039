import React from 'react';
import { RightArrowIcon } from '../../../assets/svg';

export const Download = ({ pilotCity, pilotLocation, slug }) => {
  return (
    <>
      {(pilotCity === 'St Paul PPP' ||
        pilotCity === 'Paterson' ||
        pilotCity === 'Cambridge' ||
        pilotCity === 'Richmond' ||
        pilotCity === 'Los Angeles' ||
        pilotCity === 'New Orleans' ||
        pilotCity === 'Columbia') && (
        <div className="bg-brown w-full md:w-4/6 place-self-center px-4 pb-9 md:p-24 flex flex-col md:flex-row items-center justify-between gap-8 lg:gap-14 -translate-y-[15rem] -mb-[132px] md:-mb-[145px] lg:-mb-[147px] max-w-[992px]">
          <img
            className="-translate-y-[10rem] -mb-[11rem] md:hidden place-self-start"
            src={`/images/${slug}-report.jpg`}
            width={169}
            height={219}
            alt="program"
          />{' '}
          <div>
            <h3 className="leading-[1.3] mb-4 font-bold text-[26px]">
              Download {`${pilotLocation}`}'s Evaluation Report
            </h3>
            <p className="text-[#6D6D6D] text-[16px]">
              Read a research report produced by the Center for Guaranteed Income Research (CGIR)
              which offers summaries of key findings, along with in-depth analyses of both
              quantitative and qualitative data from this pilot.
            </p>
            <a
              href={`/images/${slug}-report.pdf`}
              //href={`/images/CGIR Final Report_Saint Paul PPP_2023.pdf`}
              download
              className="font-bold text-[14px] text-bronze cursor-pointer flex items-center gap-1 uppercase mt-3 tracking-[1.8px]"
            >
              download the report
              <RightArrowIcon iconColor={'#B26F16'} />
            </a>
          </div>
          <img
            className="hidden md:block"
            src={`/images/${slug}-report.jpg`}
            width={169}
            height={219}
            alt="program"
          />
        </div>
      )}
    </>
  );
};
